import React from 'react';
import makeLinksClickable from './makeclickable'; // Import the utility function

const BulletSummary = ({ bulletSummary }) => {
  if (!bulletSummary || bulletSummary.length === 0) {
    return null; // If there's no bullet summary, return null
  }

  return (
    <div className="bullet-summary" style={{ marginBottom: '20px' }}>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        {bulletSummary.map((point, index) => (
          <li key={index} style={{ fontSize: '18px' }}>
            {makeLinksClickable(point)} 
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BulletSummary;
