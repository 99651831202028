import React from 'react';
import makeLinksClickable from './makeclickable'; // Import the utility function

const Summary = ({ text }) => (
  <div style={{ fontSize: '18px', fontStyle: 'italic' }}>
    {makeLinksClickable(text)} 
  </div>
);

export default Summary;
