// Navbar.js
import React from 'react';
import '../styles/navbar.css';
import { Link, useLocation } from 'react-router-dom';


const Navbar = ({ categories }) => {
  const location = useLocation();

  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Adding leading zeros if needed
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    return `${year}-${month}-${day}`;
  }



  const today = new Date();
const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

  // const categories = ['Trending', 'AI Tools', 'AI Research', 'AI Open Source', 'AI Usecases', 'Opinions', 'Podcasts'];


  return (
    <div className="navbar">
      {categories.map((category, index) => (
        <Link
          key={index}
          to={`/posts/${encodeURIComponent(category)}/${getFormattedDate()}`}
          className={location.pathname === `/posts/${encodeURIComponent(category)}/${getFormattedDate()}` ? 'selected' : ''}
          >
          {category}
        </Link>
      ))}
    </div>
  );
};


export default Navbar;
