// TweetList.js
import React, { useState, useEffect, useRef } from 'react';

import '../styles/tweetlist.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faEye } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import BulletSummary from './BulletSummary';
import Summary from './Summary';


const TweetList = () => {
  const { category,date } = useParams();
  const isDatePresent = Boolean(date);
  const location = useLocation();


  const [tweets, setTweets] = useState([]);
  const [selectedTweet, setSelectedTweet] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  // const [endDate, setEndDate] = useState(new Date().toISOString()); // Initialize with current date

  const [parsedDate,setParsedDate] = useState (date ? new Date(date) : new Date());
  // Construct the ISO date string manually, padding month and day with zeros if necessary
  const isoDate = `${parsedDate.getFullYear()}-${String(parsedDate.getMonth() + 1).padStart(2, '0')}-${String(parsedDate.getDate()).padStart(2, '0')}`;

  const prevCat = null

  const [endDate, setEndDate] = useState(isoDate || new Date().toISOString()); // Set endDate to date from URL, or current date if not provided
  const endDateRef = useRef(endDate);
  const [loading, setLoading] = useState(false);
  const [callCount, setCallCount] = useState(0); 
  const callCountRef = useRef(callCount);
  const canonicalUrl = `https://platform.tacq.ai${location.pathname}`;

  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  

  const handleDateChange = date => {

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding leading zero if needed
  const day = String(date.getDate()).padStart(2, '0'); // Adding leading zero if needed
  const formattedDate = `${year}-${month}-${day}`;
  // setSelectedDate(formattedDate);
  setParsedDate(date)
  console.log("parsedDate2 ", parsedDate)
  setEndDate(date)

  console.log("selected date: ", formattedDate)
  setShowCalendar(false);

    // Do whatever you need to do with the selected date
  };

  const updateMetadata = (date, category,data) => {

    const keywords = data.slice(0, 10).map(tweet => tweet.keywords[0]).join(', ');

    // console.log("keywords ", keywords)
   

    // console.log("location  ", location)

    


    const twitterTitleMeta = document.querySelector('meta[name="tacq.ai:title"]');
    const twitterDescriptionMeta = document.querySelector('meta[name="tacq.ai:description"]');

    if (twitterTitleMeta && twitterDescriptionMeta) {
      twitterTitleMeta.setAttribute('content', `tacq.ai Live AI Trend Dashboard - ${date} - ${category}`);
      twitterDescriptionMeta.setAttribute('content', `Real-time AI Trends - ${category} - ${keywords}. date ${date}.`);
      // Update other meta tags as needed
    }
  };

 

  useEffect(() => {
    // Reset the tweet list when the category changes
    console.log("category effect")
    setTweets([]);

    console.log("parsedDate3 ", parsedDate)
    
    // setEndDate(new Date().toISOString());
    // console.log("enddate1: ", endDate)
    setCallCount(0);
  }, [category]);
  useEffect(() => {
    callCountRef.current = callCount; // Update the ref whenever callCount changes
  }, [callCount]);
  



//fetch tweets on scroll end
  useEffect(() => {
   

   
    endDateRef.current = parsedDate
    
    // console.log("parsedDate2: ", parsedDate)
    const fetchTweets = async () => {
      let tempEndDate = endDateRef.current; 
      try {
       
        
        setLoading(true);
        const response = await fetch('https://tacq-python-sceixbbwfq-uc.a.run.app/tweets/trending', {
        // const response = await fetch('http://192.168.18.146:443/tweets/trending', {
        method: 'POST', // Set the HTTP method to POST
        headers: {
          'Content-Type': 'application/json' // Set the content type of the request body
          // You can add other headers as needed
        },
        body: JSON.stringify({ 
          date:tempEndDate,
          category: category || "Trending",
          count: callCountRef.current
        })
      });
       
        const data = await response.json();
        const updatedTweets = {
            tweets: data.tweets,
            date: data.date, // Assuming the date is returned from the API
            hashtags: data.hashtags
          }
        
        // setTweets(data.data);
        // setTweets(prevTweets => [...prevTweets, updatedTweets]);
        setTweets(prevTweets => [updatedTweets, ...prevTweets]);
        setLoading(false);
        // const data = await response.text();
  
        // console.log("updated tweets: ",tweets)

        updateMetadata(data.date, category,data.tweets);

        setParsedDate(new Date());
  
        // endDateRef.current = tempEndDate;
      // setCallCount(0);

    
       
        // setHtmlContent(data)

        
        

      } catch (error) {
        console.error('Error fetching tweets:', error);
        setLoading(false);
      }
    };
   

    if (callCount < 20) {
      fetchTweets();
    }
      // fetchTweets();
    
  }, [endDate,category]);

  


return (
  
  <div>
     <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    {/* <div>
      {category}
    </div> */}
    <div>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <div>Loading...</div>
        </div>
      )}
      {/* Your content goes here */}
    </div>
     {/* Calendar */}
     {/* {selectedDate && (
        <div>
          <h2>Select Date</h2>
          <DatePicker selected={selectedDate} onChange={handleDateChange}
          minDate={new Date(new Date().setDate(new Date().getDate() - 3))}
          maxDate={new Date()} // To set max date to today's date
           dateFormat="yyyy-MM-dd" />
        </div>

      )} */}




      

    {tweets.map((tweetSet, index) => (
      <div key={index}>
         {/* <div className="tweet-date" onClick={() => setSelectedDate(new Date(tweetSet.date))}>
          Date: {tweetSet.date}</div> */}
           <div className="tweet-date">
           <FontAwesomeIcon
              icon={faCalendarAlt}
              onClick={() => setShowCalendar(!showCalendar)}
              className="calendar-icon"
            />
            Date: {tweetSet.date}
           
            {showCalendar && (
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                minDate={new Date(new Date().setDate(new Date().getDate() - 3))}
                maxDate={new Date()} // To set max date to today's date
                dateFormat="yyyy-MM-dd"
                inline // Show calendar inline
                popperPlacement="bottom-start" // Adjust placement of calendar
                calendarClassName="custom-calendar" // Apply custom CSS to calendar
              />
            )}
          </div>

        {/* <div>Trending Hashtags </div> */}
        <div className="hashtag-container">
          Top Hashtags: 
      {tweetSet.hashtags.map((hashtag, idx) => (
     <a
     key={idx}
     href={`https://twitter.com/search?q=${encodeURIComponent(hashtag)}&src=trend_click&vertical=trends`}
     target="_blank"
     rel="noopener noreferrer"
     className="hashtag"
   >
     {hashtag}
   </a>
        ))}
      </div>
       
        <div className="tweet-list-container">
          {tweetSet.tweets.map((tweet) => (
            <div key={tweet._id} className="tweet-card" onClick={() => setSelectedTweet(tweet)}>
              <div className="user-info">
                {tweet.top_voices.length !== 0 ? (
                  // Display images where name is found in tweet.top_voices if it's not empty
                  tweet.tweets.map((tweetItem, index) => (
                    tweet.top_voices.includes(tweetItem.name) && (
                      <img key={index} src={tweetItem.pic} alt="Profile" />
                    )
                  )).slice(0, 3) // Display only the first three images
                ) : (
                  // Display the first three images from tweet.tweets if tweet.top_voices is empty
                  tweet.tweets.slice(0, 3).map((tweetItem, index) => (
                    <img key={index} src={tweetItem.pic} alt="Profile" />
                  ))
                )}
              </div>
                <div>
                <h4>
              {tweet.top_voices.length !== 0 ? (
              // Display names from tweets.top_voices if it's not empty
              tweet.top_voices.map((name, index) => (
                <span key={index}>
                  {name}
                  {index !== tweet.top_voices.length - 1 && ', '}
                </span>
              ))
            ).slice(0, 3) : (
              // Display the first three names from tweet.tweets if tweets.top_voices is empty
              tweet.tweets.slice(0, 3).map((t, index) => (
                <span key={index}>
                  {t.name}
                  {index !== 2 && ', '}
                </span>
              ))
            )}
                {tweet.tweets.length > 3 && ` and ${tweet.tweets.length - 3} others`}
              </h4>
                            </div>
              <p className="tweet-text">{tweet.text}</p>
              <div className="metadata">
                <span>
                  <FontAwesomeIcon icon={faHeart} />  {tweet.total_likes}
                </span>
                <span>
                  <FontAwesomeIcon icon={faEye} /> {tweet.total_views}
                </span>
              </div>
              <div className="categories">
                {tweet.tweets[0].categories.map((category, index) => (
                  <span key={index} className="category">
                    {category}
                  </span>
                ))}
              </div>
            </div>
          ))}
            {/* {loading && <div>Loading...</div>} */}
        </div>

        
        {selectedTweet && (
          <div className="popup">
            <div className="popup-content">
              <button onClick={() => setSelectedTweet(null)}>Close</button>
              <h2>{selectedTweet.text}</h2>

              <div className="data-summary" style={{ marginBottom: '20px' }}>
        <p style={{ fontSize: '18px', fontStyle: 'italic' }}>
        <Summary text={selectedTweet.summary}/>
        </p>
      </div>

      <div className="bullet-summary" style={{ marginBottom: '20px' }}>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
      <BulletSummary bulletSummary={selectedTweet.bullet_summary} /> 

      </ul>
    </div>


      <h2>Sources</h2>

              <div className="tweets-container">
                {selectedTweet.tweets.map((tweet, index) => (
                  <div key={index} className="tweet" onClick={() => window.open(tweet.tweet_url, '_blank')}>
                    <div className="tweet-header">
                      <img src={tweet.pic} alt="Profile" />
                      <h3>{tweet.name}</h3>
                    </div>
                    <p className="tweet-text">{tweet.tweet_text}</p>
                    <div className="metadata">
                      <span>
                        <FontAwesomeIcon icon={faHeart} /> {tweet.likes}
                      </span>
                      <span>
                        <FontAwesomeIcon icon={faEye} /> {tweet.views}
                      </span>
                    </div>
                    {/* <span className="category">
                        {tweet.type}
                      </span> */}
                    <div className="categories">
                     
                {tweet.categories.map((category, index) => (
                  <span key={index} className="category">
                    {category}
                  </span>
                ))}
              </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    ))}
  </div>
);
  


};
export default TweetList;
