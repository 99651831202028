// TweetList.js
import React, { useState, useEffect, useRef } from 'react';

import '../styles/tweetlist.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faEye } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


const Details = () => {
  const { pid } = useParams();
  const location = useLocation();


  const [tweets, setTweets] = useState([]);
  const [selectedTweet, setSelectedTweet] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  // const [endDate, setEndDate] = useState(new Date().toISOString()); // Initialize with current date

  const prevCat = null

  const [loading, setLoading] = useState(false);
  const [callCount, setCallCount] = useState(0); 
  const callCountRef = useRef(callCount);
  const canonicalUrl = `https://platform.tacq.ai${location.pathname}`;

  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);



  

  const updateMetadata = (data) => {
    // console.log("dataa: ",data)
    // const keywords_ = data.summary + data.keywords.slice(0, 10).join(', ');

    


    const twitterTitleMeta = document.querySelector('meta[name="tacq.ai:title"]');
    const twitterDescriptionMeta = document.querySelector('meta[name="tacq.ai:description"]');

    if (twitterTitleMeta && twitterDescriptionMeta) {
      twitterTitleMeta.setAttribute('content', `tacq.ai Live AI Trend Dashboard - ${data.text}`);
      twitterDescriptionMeta.setAttribute('content', `Real-time AI Trends  - ${data.summary}.`);
      // Update other meta tags as needed
    }


    const ogTitle = document.querySelector('meta[property="og:title"]');
    const OGDesc = document.querySelector('meta[property="og:description"]');
    const OGurl = document.querySelector('meta[property="og:url"]');

    if (ogTitle && OGDesc) {
        ogTitle.setAttribute('content', `tacq.ai Live AI Trend Dashboard - ${data.text}`);
        OGDesc.setAttribute('content', `Real-time AI Trends  - ${data.summary}.`);
        OGurl.setAttribute('content', `${canonicalUrl}`);
        // Update other meta tags as needed
      }


      const twitterTitle = document.querySelector('meta[name="twitter:title"]');
      const twitterdesc = document.querySelector('meta[name="twitter:description"]');
  
      if (twitterTitle && twitterdesc) {
        twitterTitle.setAttribute('content', `tacq.ai Live AI Trend Dashboard - ${data.text}`);
        twitterdesc.setAttribute('content', `Real-time AI Trends  - ${data.summary}.`);
          // Update other meta tags as needed
        }


  };




  useEffect(() => {
   

   
   
    const fetchTweets = async () => {
      try {
       
        
        setLoading(true);
        const response = await fetch(`https://tacq-python-sceixbbwfq-uc.a.run.app/posts/details/${pid}`, {
        // const response = await fetch(`http://192.168.18.146:443/posts/details/${pid}`, {
        method: 'POST', // Set the HTTP method to POST
        headers: {
          'Content-Type': 'application/json' // Set the content type of the request body
          // You can add other headers as needed
        },
        body: JSON.stringify({ 
          pid:pid,
          
        })
      });
    //   const html = await response.text();
        const data = await response.json();
        // const updatedTweets = {
        //     tweets: data.tweets,
        //     date: data.date, // Assuming the date is returned from the API
        //     hashtags: data.hashtags
        //   }
        
        // // setTweets(data.data);
        // // setTweets(prevTweets => [...prevTweets, updatedTweets]);
        // setTweets(prevTweets => [updatedTweets, ...prevTweets]);
        setLoading(false);
        // const data = await response.text();
  

        updateMetadata(data);

  
        // endDateRef.current = tempEndDate;
      // setCallCount(0);

    
       
        setHtmlContent(data.html)

        
        

      } catch (error) {
        console.error('Error fetching tweets:', error);
        setLoading(false);
      }
    };
   

    if (callCount < 20) {
      fetchTweets();
    }
      // fetchTweets();
    
  }, []);


  return (
   

    <div>
         {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <div>Loading...</div>
        </div>
      )}
         <Helmet>
    <link rel="canonical" href={canonicalUrl} />
  </Helmet>
      {/* Render raw HTML */}
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};
export default Details;
