// App.js
import React, { useState } from 'react';
import Navbar from './components/Navbar';
import RightNavBar from './components/Rightbar';
import Header from './components/Header';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import './App.css';
import Calendar from 'react-calendar'; // Import Calendar component



import TweetList from './components/TweetList';
import Details from './components/Details';


function getFormattedDate() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  // Adding leading zeros if needed
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }

  return `${year}-${month}-${day}`;
}

  

const App = () => {
 

  const [email, setEmail] = useState('');




  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const subscribe = () => {
    console.log("email:: ", email)
    fetch('https://tacq-python-sceixbbwfq-uc.a.run.app/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => {
      if (response.ok) {
        alert('Subscribed successfully!');
        setEmail(''); // Clear email input after successful subscription
      } else {
        throw new Error('Failed to subscribe.');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('Failed to subscribe. Please try again later.');
    });
  };



  let r = {
    "tweets": [
      {
        "id": 1,
        "user": {
          "username": "user1",
          "profileImageUrl": "https://pbs.twimg.com/profile_images/1715475762516840448/kst_-vG1_x96.jpg"
        },
        "text": "This is a sample tweet.",
        "likes": 10,
        "comments": 5
      },
     
    ]
  }




  return (
    
    <Router>
      <div className="app-container">
        <Header />
        <div className="hero-section">
        {/* <img src="/256.png" alt="Website Logo" className="logo" /> */}
        <div className="hero-content">
      <h1>Unlock the Best of #AItwitter Every Day!</h1>
      <p>Stay Ahead, Stay Informed: AI Insights Straight from Twitter!</p>
      <div className="facts">
        <div className="fact">
          <h3>Daily AI Trends</h3>
          <p>Top AI Voices </p>
        </div>
        <div className="fact">
          <h3>Organized AI Updates</h3>
          <p> Sorted AI News & Posts </p>
        </div>
        <div className="fact">
          <h3>Uncover Trends/Opinions</h3>
          <p>Public Sentiment </p>
        </div>
      </div>

      <div className="subscribe-section">
      <h2>Subscribe for Daily AI Updates:</h2>
      <div className="subscribe-form" id="subscribeForm">
      <input 
          type="email" 
          placeholder="Enter your email address" 
          value={email} 
          onChange={handleEmailChange} 
        />
        <button onClick={subscribe}>Subscribe</button>
      </div>
    </div>

    </div>
    </div>
   
        <div className="main-content">
          <div className="content-left">
            <Navbar categories={['Trending', 'AI Tools', 'AI Research', 'AI Open Source', 'AI Usecases', 'Opinions', 'Podcasts']} />
            
            <Routes>
              {/* <Route path="/" element={<TweetList category="Trending" />} /> */}
              <Route
                path="/"
                element={<Navigate to={`/posts/Trending/${getFormattedDate()}`} replace />}
              />
              <Route path="/posts/:category/:date" element={<TweetList />} />
              <Route path="/posts/details/:pid" element={<Details />} />
              {/* <Route path="/tweets/:category/" element={<TweetList />} /> */}
            </Routes>
          </div>
          <div className="content-right">
         
          </div>
        </div>
      </div>
    </Router>
  );
};


  
export default App;
