// components/Header.js
import '../styles/header.css';
import React from 'react';

const Header = () => {
  return (
    <div className="header">
      <div className="left-section">
      <img src="/256.png" alt="Logo" class="logo"/> 
        <span className="tacq">Tacq.ai</span>
      </div>

      {/* { <div className="center-section">
        <input type="text" placeholder="Search" /> */}
        {/* You can add additional elements like buttons, icons, etc., for search functionality */}
      {/* </div> } */}
      
      <div className="right-section">
     <a href ="https://www.tacq.ai" target="_blank" ><span className="login">Newsletter</span></a> 
      </div>
    </div>
  );
};

export default Header;
