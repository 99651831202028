import React from 'react';

const trimTrailingEllipses = (url) => {
  // Regex to remove trailing ellipses or three dots
  const trailingPattern = /\.\.\.$|…+$/; 
  return url.replace(trailingPattern, ''); // Remove the trailing characters
};

// Function to convert plain text with URLs into clickable links

const makeLinksClickable = (text) => {
  if (!text) {
    return null;
  }

  // Improved regex to match URLs, ensuring they end properly without trailing punctuation
  const urlPattern = /https?:\/\/[^\s,]+/g; // Regex pattern to find URLs, avoiding spaces and certain punctuation
  const parts = text.split(urlPattern); // Split text based on URLs
  const matches = text.match(urlPattern) || []; // Get all matching URLs

  // Return a React fragment containing clickable links and text parts
  return (
    <React.Fragment>
      {parts.map((part, index) => (
        <span key={index}>
          {part}
          {index < matches.length ? (
            <a
            href={trimTrailingEllipses(matches[index])}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#1a73e8', textDecoration: 'none' }}
            >
              {trimTrailingEllipses(matches[index])} 
            </a>
          ) : null}
        </span>
      ))}
    </React.Fragment>
  );
};

export default makeLinksClickable;
